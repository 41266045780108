import styled from 'styled-components';
import { rem } from 'polished';

export const StyledRevealingSlider = styled.div`
  display: grid;
  overflow: hidden;
  gap: ${rem(`18px`)};
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  ${({ theme }) => theme.breakpoints.phablet`
    gap: ${rem(`24px`)};
    grid-template-columns: repeat(2, 1fr);
  `}
  ${({ theme }) => theme.breakpoints.tablet`
    gap: ${rem(`36px`)};
    grid-template-columns: repeat(3, 1fr);
  `}
`;
