import React, { useEffect, useRef, useState } from 'react';
import { kebabCase, get } from 'lodash';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';
import { Social } from '@/components/Social';
import { CallToAction } from '@/components/CallToAction';
import Layout, { Main } from '@/components/Layout';
import { Content } from '@/components/Content';
import { Seo } from '@/components/SEO';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { RevealingSlider } from '@/components/RevealingSlider';
import { SectionHeader } from '@/components/SectionHeader';
import { faArrowRight, faTag, faTags } from '@fortawesome/pro-light-svg-icons';
import { Badge } from '@/components/Badge';
import { Text } from '@/components/Text';
import { HeaderWithMore } from '@/components/HeaderWithMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import useScrollPosition from '@/hooks/useScrollPosition';
import useWindowDimensions from '@/hooks/useWindowDimensions';

const isBrowser = typeof window !== `undefined`;

interface IStyledTags {
  theme: any;
  floatTags: boolean;
}

interface ITagsPageTemplate {
  group?: any;
}

interface ITagsPage {
  data: {
    blogPage: any;
    allContentfulArticle: any;
  };
}

interface IHeaderSection {
  group?: any;
  title?: string;
  tagsRef?: any;
}

export const StyledTags = styled.div<IStyledTags>`
  .body {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .main {
    flex: 0 1 auto;
  }
  && .heading .content {
    padding-top: ${rem(60)};
  }
  .spacer {
    flex: 1 0 24px;
  }
  .aside {
    flex: 1 0 350px;
    max-width: 350px;
  }
  .cta {
    margin-bottom: ${rem(`24px`)};
  }
  .content {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: 1fr;
    gap: ${rem(`12px`)};
    ${({ theme }) => theme.breakpoints.phablet`
      gap: ${rem(`24px`)};
    `}
  }
  .section {
    max-width: ${rem(`1200px`)};
    width: 100%;
    margin-bottom: ${rem(`120px`)};
    &:last-child {
      margin-bottom: 0;
    }
  }
  .section-header {
    margin-bottom: ${rem(`12px`)};
    .title__text,
    .title {
      margin-bottom: 0;
    }
  }
  .section-subheader {
    margin-bottom: ${rem(`12px`)};
    &__content {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      flex: 1 0 auto;
      .body1 {
        margin-bottom: 0;
      }
    }
  }
  .section-subheader .spacer {
    flex: 0 1 6px;
  }
  .title {
    display: flex;
    margin-bottom: ${rem(`24px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      margin-bottom: 0;
    `}
  }
  .title__icon {
    margin-top: ${rem(`16px`)};
    margin-right: ${rem(`6px`)};
    path {
      fill: url('#rainbow_1_svg') #fff;
    }
  }
  .tags-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: ${rem(`440px`)};
    margin-bottom: ${rem(`48px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      margin-top: ${rem(`156px`)};
    `}
    ${({ floatTags }) =>
      floatTags
        ? css`
            position: fixed;
            left: 0;
          `
        : css``}
  }
  .tags-list__item {
    flex: 1 0 auto;
    margin-bottom: ${rem(`12px`)};
    &:last-child {
      margin: 0;
    }
  }
`;

const HeaderSection = ({ title, group, tagsRef }: IHeaderSection) => {
  return (
    <Content restrictWidth noMargin>
      <div className="title">
        <FontAwesomeIcon size="4x" className="title__icon" icon={faTags} />
        <Text type="title1" tag="h2" color="white_1">
          {title}
        </Text>
      </div>
      <div className="tags-list" ref={tagsRef}>
        {group
          .sort((a, b) => b.totalCount - a.totalCount)
          .map((tag) => (
            <AnchorLink
              key={uuidv4()}
              to={`/tags#${kebabCase(tag.fieldValue)}`}
              stripHash
              className="tags-list__item"
            >
              <Badge
                randomColor
                large
                stretch
                rightSection={
                  <FontAwesomeIcon
                    className="badge__right-icon"
                    icon={faArrowRight}
                  />
                }
              >
                {tag.fieldValue}
              </Badge>
            </AnchorLink>
          ))}
      </div>
    </Content>
  );
};

const SectionSubheader = ({ tag }: any) => (
  <div className="section-subheader__content">
    <Text noRhythem type="body1" tag="div" color="gray_3">
      RECENT 3 OF TAG:
    </Text>
    <div className="spacer" />
    <Badge randomColor noHover large>
      {tag.fieldValue}
    </Badge>
  </div>
);

const TagsPageDesktopTemplate = ({ group }: ITagsPageTemplate) => (
  <Content restrictWidth noMargin>
    {group
      .sort((a, b) => b.totalCount - a.totalCount)
      .map((tag) => (
        <section
          className="section"
          id={kebabCase(tag.fieldValue)}
          key={tag.fieldValue}
        >
          <SectionHeader
            icon={faTag}
            title={tag.fieldValue}
            className="section-header"
          />
          <div className="section-subheader">
            {tag.totalCount < 4 ? (
              <SectionSubheader tag={tag} />
            ) : (
              <HeaderWithMore
                linkTo={`/tags/${tag.fieldValue}`}
                linkText="Show more"
                noMargin
              >
                <SectionSubheader tag={tag} />
              </HeaderWithMore>
            )}
          </div>

          <main className="main">
            <RevealingSlider slides={tag.edges.map(({ node }) => node)} />
          </main>
        </section>
      ))}
  </Content>
);

const TagsPage = ({ data }: ITagsPage) => {
  const group = get(data, `allContentfulArticle.group`);
  const page = get(data, `tagsPage`);
  const {
    title,
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;
  const image = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);
  const tagsRef = useRef<HTMLDivElement>();
  const { width: windowWidth } = isBrowser
    ? useWindowDimensions()
    : { width: 0 };
  const { y: scrollPositionY } = isBrowser ? useScrollPosition() : { y: 0 };
  const [floatTags, setFloatTags] = useState(false);

  useEffect(() => {
    if (!tagsRef?.current?.offsetTop) {
      return;
    }
    setFloatTags(
      scrollPositionY > tagsRef?.current?.offsetTop && windowWidth > 1500,
    );
  }, [scrollPositionY, tagsRef?.current, windowWidth]);

  return (
    <StyledTags floatTags={floatTags}>
      <Layout
        location={{ pathname: `/tags` }}
        coverImage={image}
        variant={3}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <HeaderSection title={title} group={group} tagsRef={tagsRef} />
        }
        desktopBody={<TagsPageDesktopTemplate group={group} />}
        desktopCta={
          <Content>
            <CallToAction
              hookId={ctaHookId}
              icon={ctaIcon}
              title={ctaTitle}
              body={ctaBody.ctaBody}
              maxWidth="100%"
              className="cta"
            />
            <Social />
          </Content>
        }
      >
        <Seo
          title={`Tags | ${title}`}
          metaTitle={`Tags | ${title}`}
          metaDesc={title}
          cover={heroImage}
          slug="/tags"
          templateKey="tags"
        />
        <Main background="transparent" disableVertPadding={false}>
          <HeaderSection title={title} group={group} tagsRef={tagsRef} />
          <TagsPageDesktopTemplate group={group} />
        </Main>
      </Layout>
    </StyledTags>
  );
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    tagsPage: contentfulPage(slug: { eq: "tags" }) {
      title
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 100, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    allContentfulArticle {
      group(field: tags, limit: 1000) {
        field
        fieldValue
        totalCount
        edges {
          node {
            title
            slug
            id
            timeToRead
            tags
            createDate(formatString: "MMMM Do, YYYY")
            heroImage {
              description
              gatsbyImageData(width: 344, height: 180, quality: 100)
            }
            excerpt
            author {
              name
              slug
              image {
                gatsbyImageData(
                  width: 40
                  height: 40
                  quality: 100
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`;
