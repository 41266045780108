import { Link } from 'gatsby';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

export const StyledUpWorkSocial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: color 0.2s ease-in-out;
  border-radius: 10px;
  max-width: ${rem(`64px`)};
  .link {
    flex: 0 1 auto;
    background: rgba(255, 255, 255, 0.3);
    border-radius: ${rem(`6px`)};
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: ${({ theme }) => theme.colors.pink_3};
    }
    svg {
      max-width: ${rem(`56px`)};
    }
  }
`;
export const UpWorkSocial = () => {
  return (
    <StyledUpWorkSocial>
      <Link
        to="https://www.upwork.com/ag/domusnetwork"
        target="_blank"
        className="link"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="upwork"
          style={{ fill: `#001529`, opacity: 1, padding: `${rem(`5px`)}` }}
        >
          <path d="M13.593 20.5h-2.681a.501.501 0 0 1-.489-.606l1.553-7.172a14.521 14.521 0 0 1-.902-1.298c-.367 2.678-2.699 4.749-5.513 4.749C2.495 16.174 0 13.713 0 10.688V4a.5.5 0 0 1 .5-.5h2.622a.5.5 0 0 1 .5.5v6.688c.001 1.043.872 1.894 1.939 1.896 1.068-.002 1.939-.853 1.94-1.896V4a.5.5 0 0 1 .5-.5h2.622c.222 0 .417.146.479.358a20.852 20.852 0 0 0 1.979 4.562c.994-2.202 2.881-3.486 5.205-3.486 3.15 0 5.712 2.519 5.712 5.614 0 3.102-2.562 5.625-5.712 5.625a6.97 6.97 0 0 1-3.188-.773l-1.019 4.705a.495.495 0 0 1-.486.395zm-2.061-1h1.657l1.077-4.975a.497.497 0 0 1 .754-.318c1.023.642 2.122.967 3.267.967 2.599 0 4.712-2.075 4.712-4.625 0-2.544-2.114-4.614-4.712-4.614-2.182 0-3.905 1.401-4.61 3.749a.502.502 0 0 1-.893.137c-1.018-1.502-1.93-3.423-2.53-5.32H8.502v6.188c-.002 1.595-1.321 2.894-2.94 2.896-1.619-.002-2.937-1.301-2.939-2.895V4.5H1v6.188c0 2.474 2.046 4.486 4.561 4.486 2.516 0 4.563-2.013 4.563-4.486v-1.12a.5.5 0 0 1 .95-.218c.549 1.129 1.165 2.115 1.83 2.932a.498.498 0 0 1 .101.421L11.532 19.5zm6.756-5.911c-1.106 0-2.202-.425-3.257-1.263a.5.5 0 0 1-.178-.497l.218-1.011c.39-2.113 1.561-3.312 3.217-3.312 1.704 0 3.09 1.364 3.09 3.041 0 1.678-1.387 3.042-3.09 3.042zm-2.391-1.86c.794.571 1.597.86 2.39.86 1.152 0 2.09-.916 2.091-2.041 0-1.125-.938-2.041-2.09-2.041-1.466 0-2.021 1.342-2.229 2.468l-.162.754z" />
        </svg>
      </Link>
    </StyledUpWorkSocial>
  );
};
