import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '../Text';

interface IStyledHeaderWithMore {
  noMargin?: boolean;
}

const StyledHeaderWithMore = styled.div<IStyledHeaderWithMore>`
  display: flex;
  margin-bottom: ${({ noMargin }) => (noMargin ? `0` : rem(`12px`))};
  flex-direction: column;
  .header {
    display: flex;
    align-items: flex-start;
    flex: 0 1 auto;
    flex-direction: column;
  }
  .link {
    flex: 0 1 auto;
    display: flex;
    justify-content: flex-end;
  }
  .link__title {
    display: flex;
    align-items: center;
  }
  .link__text {
    color: ${({ theme }) => theme.colors.white_1};
    transition: color 300ms ease-in-out;
  }
  .link__icon {
    color: ${({ theme }) => theme.colors.pink_1};
    transition: color 300ms ease-in-out;
    margin-left: ${rem(`6px`)};
  }
  ${({ theme }) => theme.breakpoints.desktop`
    flex-direction: row;
    .header {
      align-items: center;
      flex: 1 0 auto;
      flex-direction: row;
    }
    .link {
      justify-content: flex-start;
      &:hover {
        .link__text {
          color: ${theme.colors.pink_1};
          transition: color 300ms ease-in-out;
        }
        .link__icon {
          color: ${theme.colors.yellow_1};
          transition: color 300ms ease-in-out;
        }
      }
    }
  `}
`;

interface IHeaderWithMore {
  children: any;
  linkTo: string;
  linkText: string;
  noMargin?: boolean;
  className?: string;
}

export const HeaderWithMore = ({
  children,
  linkTo,
  linkText,
  noMargin,
  className,
}: IHeaderWithMore) => {
  return (
    <StyledHeaderWithMore className={className} noMargin={noMargin}>
      <div className="header">{children}</div>
      <Link to={linkTo} className="link">
        <div className="link__title">
          <Text
            noRhythem
            type="body3"
            tag="span"
            color="gray_3"
            className="link__text"
          >
            {linkText}
          </Text>
          <Text
            noRhythem
            type="body3"
            tag="span"
            color="gray_3"
            className="link__icon"
          >
            <FontAwesomeIcon icon={faChevronDoubleRight} />
          </Text>
        </div>
      </Link>
    </StyledHeaderWithMore>
  );
};
