import React from 'react';
import { take } from 'lodash';
import { StyledRevealingSlider } from './styles';
import { PostCard } from '../PostCard';

interface IRevealingSlider {
  slides: any;
}

export const RevealingSlider = ({ slides }: IRevealingSlider) => {
  return (
    <StyledRevealingSlider>
      {take(slides, 3).map((article: any) => {
        const { createDate, ...rest } = article;
        return (
          <PostCard vertical date={createDate} {...rest} key={rest.title} />
        );
      })}
    </StyledRevealingSlider>
  );
};
