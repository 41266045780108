import React from 'react';
import { Link } from 'gatsby';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  faLinkedin,
  faSquareGithub,
  faSquareWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpWorkSocial } from './UpworkSocial';

export const StyledSocial = styled.div`
  display: grid;
  grid-gap: ${rem(`6px`)};
  grid-template-columns: repeat(4, 56px);
  .social-link__icon {
    color: ${({ theme }) => theme.colors.white_1_30};
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.colors.pink_3};
    }
  }
  ${(props) => props.theme.breakpoints.phone`
    grid-gap: ${rem(`12px`)};
  `}
  ${(props) => props.theme.breakpoints.phablet`
    margin-left: ${rem(`64px`)};
  `}
  ${(props) => props.theme.breakpoints.tablet`
    grid-gap: ${rem(`24px`)};
    margin-left: ${rem(`64px`)};
  `}
`;

export const Social = () => (
  <StyledSocial>
    <Link to="https://www.linkedin.com/company/domusnetwork/" target="_blank">
      <FontAwesomeIcon
        size="4x"
        className="social-link__icon"
        icon={faLinkedin}
      />
    </Link>
    <Link to="https://github.com/Domusnetwork" target="_blank">
      <FontAwesomeIcon
        size="4x"
        className="social-link__icon"
        icon={faSquareGithub}
      />
    </Link>
    <Link to="https://wa.me/message/OWWIU4C5SXVXB1" target="_blank">
      <FontAwesomeIcon
        size="4x"
        className="social-link__icon"
        icon={faSquareWhatsapp}
      />
    </Link>
    <UpWorkSocial />
  </StyledSocial>
);
