import styled from 'styled-components';
import { rem } from 'polished';
import React, { forwardRef } from 'react';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@/components/Text';
import { MdContent } from '@/components/MdContent';

const StyledSectionHeader = styled.header`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: ${rem(`60px`)};
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${rem(`36px`)};
    &__icon {
      margin-top: ${rem(`8px`)};
      path {
        fill: url('#rainbow_1_svg') #fff;
      }
    }
    &__text {
      margin-left: ${rem(`24px`)};
    }
  }
  .subtitle {
    flex: 1 0 100%;
    text-align: center;
  }
`;

export const SectionHeader = forwardRef(
  (
    {
      title,
      icon,
      subtitle,
      className,
    }: {
      title: string;
      icon: IconDefinition;
      subtitle?: string;
      className?: string;
    },
    ref,
  ) => (
    <StyledSectionHeader className={`header ${className}`}>
      <div className="title" ref={ref as any}>
        <FontAwesomeIcon size="4x" className="title__icon" icon={icon} />
        <Text
          className="title__text"
          type="title1"
          tag="h4"
          color="gray_1"
          noRhythem
        >
          {title}
        </Text>
      </div>
      {subtitle && (
        <Text className="subtitle" type="body1" tag="div" color="gray_3_65">
          <MdContent content={subtitle} />
        </Text>
      )}
    </StyledSectionHeader>
  ),
);
